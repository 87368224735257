import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useContext } from "react";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import { setDocumentScrollPosition } from "utils";

interface ListQueryParams {
  queryKey: string;
  retailed?: boolean;
  withParams?: string;
  status?: string | number;
  search?: string;
  sort?: string;
  currentPage: number;
  scrollPosition?: string;
  dealershipID?: string | null;
}

export const getValuationQueryKey = ({
  queryKey,
  retailed,
  withParams,
  status,
  search,
  sort,
  currentPage,
  dealershipID,
}: ListQueryParams) => [
  queryKey,
  {
    retailed,
    with: withParams,
    status,
    search,
    sort,
    page: currentPage,
    dealership_id: dealershipID,
  },
];

export const useListQuery = <T>(
  queryParams: ListQueryParams,
  queryFunction: QueryFunction<T, QueryKey>,
  queryOptions?: UseQueryOptions<T, Error>
) => {
  const { dealershipID } = useDealership();
  const { userID } = useContext(AuthContext);
  const { queryKey, ...params } = queryParams;
  const queries = getValuationQueryKey({
    ...params,
    queryKey: `${queryKey}-${userID}`,
    dealershipID: dealershipID || undefined,
  });

  return useQuery<T, Error>(queries, queryFunction, {
    ...queryOptions,
    onSuccess: () => {
      if (queryParams?.scrollPosition) {
        setDocumentScrollPosition(queryParams.scrollPosition || "0");
      }
    },
    onError: (error) => {
      console.log("Error Fetching List: ", error);
    },
    cacheTime: 5 * (60 * 1000),
    refetchOnWindowFocus: true,
    enabled: !!dealershipID,
  });
};
