import { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import {
  FieldSearchBar,
  ListVehicles,
  LoadVehicle,
  StatusBarTabs,
} from "components/app";
import { LoadingSpinner } from "components/Loading/LoadingSpinner";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Breadcrumbs, Pagination } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { SearchContext } from "context/SearchContext";

import {
  ALL,
  DEFAULT_ITEMS_PER_PAGE,
  FLOW_TYPE,
  PENDINGSALE,
  SOLDVIADIRECTOFFER,
  STATUS_LIST,
  StorageKeys,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { AcquisitionService } from "api/client";

import { useSessionStorage } from "hooks";
import { useListQuery } from "hooks/useListQuery";

import "./styles.scss";

export const AcquisitionList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useSessionStorage(
    StorageKeys.RetailDealershipAcquisitionList,
    1
  );
  const [currentStatus, setCurrentStatus] = useState<string | number>(ALL.key);
  const { searchTxt } = useContext(SearchContext);
  const { hasPermission } = useContext(AuthContext);

  useEffect(() => {
    if (searchTxt && currentPage !== 1) {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTxt]);

  const canView = hasPermission(PermissionCategory.RETAIL_INSPECTIONS, [
    PermissionAction.VIEW,
    PermissionAction.VIEW_ANY,
  ]);

  const filteredOptions = hasPermission(
    PermissionCategory.DIRECT_OFFERS,
    PermissionAction.CREATE
  )
    ? STATUS_LIST.Acquisition
    : STATUS_LIST.Acquisition.filter((item) => item.key !== PENDINGSALE.key);

  const { data, isInitialLoading, isRefetching } = useListQuery(
    {
      queryKey: StorageKeys.RetailDealershipAcquisitionList,
      retailed: true,
      withParams: "dealership,owner,images,valuations,assessments,expenses",
      status: currentStatus !== ALL.key ? currentStatus : "",
      search: searchTxt,
      currentPage: currentPage,
    },
    AcquisitionService.getVehicleList,
    {
      enabled: canView,
    }
  );

  const handleFetchNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleFetchPrev = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleFetchSelected = (index: number) => {
    setCurrentPage(index);
  };

  const handleChangeStatus = (status: string | number) => {
    setCurrentStatus(status);
    setCurrentPage(1);
  };

  const handleGoSummary = (id: string) => {
    navigate(`${id}/summary`);
  };

  const filteredStatus = STATUS_LIST.SoldAcquisition.filter(
    ({ key }) =>
      key !== SOLDVIADIRECTOFFER.key ||
      hasPermission(PermissionCategory.DIRECT_OFFERS, PermissionAction.CREATE)
  );

  const handleStatus = useMemo(() => {
    if (
      currentStatus === "sold-all" ||
      filteredStatus?.filter(({ key }) => key === currentStatus).length > 0
    ) {
      return "sold-all";
    } else {
      return currentStatus;
    }
  }, [currentStatus]);

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Stocklist", SECTIONS.RETAIL_DEALERSHIP)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="flex w-full gap-5">
          <div className="flex flex-col gap-2.5 pt-2 w-430">
            <Breadcrumbs
              paths={[
                { title: "Retail Dealership", route: "/retail-dealership" },
                { title: "Stocklist", route: "/retail-dealership/stocklist" },
              ]}
            />
            <div className="flex items-center gap-2">
              <div className="title">Stocklist</div>
              {isRefetching && <LoadingSpinner />}
            </div>
            <div className="flex flex-row gap-5 items-center">
              {canView && (
                <Pagination
                  currentPage={currentPage}
                  pageLimit={data?.meta?.per_page || DEFAULT_ITEMS_PER_PAGE}
                  total={data?.meta?.total || 0}
                  onNextPage={handleFetchNext}
                  onPrevPage={handleFetchPrev}
                  onIndexedPage={handleFetchSelected}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <FieldSearchBar />
            <div>
              <StatusBarTabs
                currentStatus={handleStatus}
                options={filteredOptions}
                onSelect={handleChangeStatus}
              />

              {(currentStatus === "sold-all" ||
                filteredStatus?.filter(({ key }) => key === currentStatus)
                  .length > 0) && (
                <div className="dashboard__subtoolbar">
                  {filteredStatus?.map((item) => (
                    <button
                      key={item.key}
                      className={
                        currentStatus === item.key
                          ? `btn-subtool selected`
                          : "btn-subtool"
                      }
                      onClick={() => handleChangeStatus(item.key)}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="dashboard__content">
          {!canView ? (
            <NoPermissionMessage />
          ) : isInitialLoading ? (
            <LoadVehicle />
          ) : !data || data.data.length === 0 ? (
            <div className="dashboard__content--empty">
              No Inspections Created Yet
            </div>
          ) : (
            <ListVehicles
              vehicles={data.data}
              onSelect={handleGoSummary}
              flow={FLOW_TYPE.RETAIL_DEALERSHIP}
            />
          )}
        </div>
        <div className="dashboard__footer">
          {canView && (
            <Pagination
              currentPage={currentPage}
              pageLimit={data?.meta?.per_page || DEFAULT_ITEMS_PER_PAGE}
              total={data?.meta?.total || 0}
              onNextPage={handleFetchNext}
              onPrevPage={handleFetchPrev}
              onIndexedPage={handleFetchSelected}
            />
          )}
        </div>
      </div>
    </>
  );
};
