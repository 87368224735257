import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import {
  FieldSearchBar,
  ListVehicles,
  LoadVehicle,
  StatusBarTabs,
} from "components/app";
import { LoadingSpinner } from "components/Loading/LoadingSpinner";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Breadcrumbs, Pagination } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { SearchContext } from "context/SearchContext";

import {
  ALL,
  DEFAULT_ITEMS_PER_PAGE,
  FLOW_TYPE,
  NEGOTIATING,
  STATUS_LIST,
  StorageKeys,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { NegotiationService } from "api/client";

import { useSessionStorage } from "hooks";
import { useListQuery } from "hooks/useListQuery";

import "./styles.scss";
export const NegotiationList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useSessionStorage(
    StorageKeys.RetailDealershipNegotiationList,
    1
  );
  const [currentStatus, setCurrentStatus] = useState<string | number>(ALL.key);
  const { searchTxt } = useContext(SearchContext);
  const { hasPermission } = useContext(AuthContext);

  useEffect(() => {
    if (searchTxt && currentPage !== 1) {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTxt]);

  const canView = hasPermission(PermissionCategory.RETAIL_INSPECTIONS, [
    PermissionAction.VIEW,
    PermissionAction.VIEW_ANY,
  ]);

  const { data, isInitialLoading, isRefetching, isError } = useListQuery(
    {
      queryKey: StorageKeys.RetailDealershipNegotiationList,
      retailed: true,
      withParams: "dealership,owner,images,valuations,assessments,expenses",
      status: currentStatus !== ALL.key ? currentStatus : "",
      search: searchTxt,
      currentPage: currentPage,
    },
    NegotiationService.getVehicleList,
    {
      enabled: canView,
    }
  );

  const handleFetchNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleFetchPrev = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleFetchSelected = (index: number) => {
    setCurrentPage(index);
  };

  const handleChangeStatus = (status: string | number) => {
    setCurrentStatus(status);
    setCurrentPage(1);
  };

  const handleGoSummary = (id: string) => {
    navigate(`${id}/summary`);
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Valued", SECTIONS.RETAIL_DEALERSHIP)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="flex w-full gap-5">
          <div className="flex flex-col gap-2.5 pt-2 w-430">
            <Breadcrumbs
              paths={[
                { title: "Retail Dealership", route: "/retail-dealership" },
                { title: "Valued", route: "/retail-dealership/valued" },
              ]}
            />
            <div className="flex items-center gap-2">
              <div className="title">Valued</div>
              {isRefetching && <LoadingSpinner />}
            </div>
            <div className="flex flex-row gap-5 items-center">
              {canView && (
                <Pagination
                  currentPage={currentPage}
                  pageLimit={data?.meta?.per_page || DEFAULT_ITEMS_PER_PAGE}
                  total={data?.meta?.total || 0}
                  onNextPage={handleFetchNext}
                  onPrevPage={handleFetchPrev}
                  onIndexedPage={handleFetchSelected}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <FieldSearchBar />
            <StatusBarTabs
              currentStatus={currentStatus}
              options={STATUS_LIST.Negotiation}
              onSelect={handleChangeStatus}
            />
          </div>
        </div>
        <div className="dashboard__content">
          {!canView ? (
            <NoPermissionMessage />
          ) : isInitialLoading ? (
            <LoadVehicle />
          ) : isError ? (
            <span>Something went wrong. Please, try again.</span>
          ) : !data || data.data.length === 0 ? (
            <div className="dashboard__content--empty">
              No Inspections Created Yet
            </div>
          ) : (
            <ListVehicles
              vehicles={data.data}
              onSelect={handleGoSummary}
              flow={FLOW_TYPE.RETAIL_DEALERSHIP}
            />
          )}
        </div>
        <div className="dashboard__footer">
          {canView && (
            <Pagination
              currentPage={currentPage}
              pageLimit={data?.meta?.per_page || DEFAULT_ITEMS_PER_PAGE}
              total={data?.meta?.total || 0}
              onNextPage={handleFetchNext}
              onPrevPage={handleFetchPrev}
              onIndexedPage={handleFetchSelected}
            />
          )}
        </div>
      </div>
    </>
  );
};
